import React, { useState, useEffect } from "react";
import {
  getArticle,
  updateArticle,
  createArticle,
  checkSlug,
} from "../../articleResource";
import { imageUploadUrl } from "../../api";
import { navigate } from "@reach/router";
import Loadable from "@loadable/component";
import slugify from "slugify";
import { RadioButton } from "primereact/radiobutton";
import {
  TextField,
  CheckboxComponent,
  FloatWrapper,
} from "../../components/PrimeReact";
import styled from "styled-components";
import { Button } from "primereact/button";

const FieldWrapper = styled.div`
  margin: 5px 0px;
`;

const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > button {
    margin: 0px 5px;
  }
`;

const LoadableEditor = Loadable(() => import("./Editor"));

const PostEditor = ({ id, loginState }) => {

  const generateAuthor = () => {
    try {
      return {
        firstName: loginState.firstName,
        lastName: loginState.lastName,
        image: { src: loginState.profileImage },
      };
    } catch {
      return null;
    }
  };

  const [articleTitle, setArticleTitle] = useState();
  const [articleData, setArticleData] = useState("");
  const [articleSlug, setArticleSlug] = useState();
  const [articlePublic, setArticlePublic] = useState(false);
  const [customSlug, setCustomSlug] = useState(false);
  const [articleImages, setArticleImages] = useState([]);
  const [checkedImage, setCheckedImage] = useState();
  const [author, setAuthor] = useState(generateAuthor());

  const save = async () => {
    if (id) {
      await updateArticle(id, {
        slug: articleSlug,
        title: articleTitle,
        data: articleData,
        public: articlePublic,
        image: checkedImage,
        author: author,
        template: "ckeditor",
      });
    } else {
      await createArticle({
        slug: articleSlug,
        title: articleTitle,
        data: articleData,
        public: articlePublic,
        image: checkedImage,
        author: author,
        template: "ckeditor",
      });
    }
    navigate("/admin");
  };



  const titleUpdate = (title) => {
    setArticleTitle(title);
  };

  const titleBlur = async (title) => {
    if (!customSlug)
      setArticleSlug(
        await checkSlugFn(slugify(title, { lower: true, strict: true }))
      );
  };

  const slugUpdate = (slug) => {
    setCustomSlug(true);
    setArticleSlug(slug);
  };

  const checkSlugFn = async (slug) => {
    const res = await checkSlug(slug);
    return res.slug;
  };

  useEffect(() => {
    (async () => {
      if (id) {
        console.log(id);
        let post = await getArticle(id);
        if (post) {
          setArticleTitle(post.title);
          setArticleData(post.data);
          setArticlePublic(post.public);
          setCustomSlug(true);
          setArticleSlug(post.slug);
          setCheckedImage(post.image);

          if (post.author) {
            setAuthor(post.author);
          }
        }
      }
    })();
  }, [id]);

  useEffect(() => {
    //console.log(articleData);

    //<figure class="image">
    //<img src="https://chrisandquynh.com/uploaded/blog/jackson-4ab80cec751413c9b606059ec6bd4610f441094f94077bb775fdcb04ba6a2f4a.jpg">
    //<figcaption>Jackson in a milk coma</figcaption>
    //</figure>
    const imgRegex = /\<figure.*?\<img src=\"(?<img>.+?)\"\>.*?\<\/figure\>/gm;
    const input = articleData;
    let result;
    const images = [];
    do {
      result = imgRegex.exec(input);

      if (result) images.push(result.groups["img"]);
    } while (result);

    setArticleImages(images);
  }, [articleData]);

  return (
    <div className={"page-editor"}>
      <FieldWrapper>
        <TextField
          id={"title"}
          label={"Title"}
          value={articleTitle}
          onChange={(evt) => titleUpdate(evt.target.value)}
          onBlur={(evt) => titleBlur(evt.target.value)}
        />
      </FieldWrapper>
      <FieldWrapper>
        <TextField
          id={"slug"}
          label={"Article Slug"}
          value={articleSlug}
          onChange={(evt) => slugUpdate(evt.target.value)}
        />
      </FieldWrapper>
      <FieldWrapper>
        <FloatWrapper>
          <CheckboxComponent
            label={"Public"}
            checked={articlePublic}
            onChange={(evt) => setArticlePublic(evt.target.checked)}
          />
        </FloatWrapper>
      </FieldWrapper>

      <FieldWrapper>
        <TextField
          id={"firstName"}
          label={"Author First Name"}
          value={author?.firstName}
          onChange={(evt) =>
            setAuthor({ ...author, firstName: evt.target.value })
          }
        />
      </FieldWrapper>
      <FieldWrapper>
        <TextField
          id={"lastName"}
          label={"Author Last Name"}
          value={author?.lastName}
          onChange={(evt) =>
            setAuthor({ ...author, lastName: evt.target.value })
          }
        />
      </FieldWrapper>
      <FieldWrapper>
        <TextField
          id={"image"}
          label={"Author Profile Image"}
          value={author?.image?.src}
          onChange={(evt) =>
            setAuthor({ ...author, image: { src: evt.target.value } })
          }
        />
      </FieldWrapper>

      <LoadableEditor
        url={imageUploadUrl}
        onData={setArticleData}
        initialData={articleData}
      />
      <table>
        <tbody>
          {articleImages.map((data, index) => (
            <tr key={index}>
              <td>
                <img width={100} src={data}></img>
              </td>
              <td>
                <RadioButton
                  value={data}
                  name="articleImage"
                  onChange={(e) => setCheckedImage(e.value)}
                  checked={checkedImage === data}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ControlWrapper>
        <Button
          label={"Save Changes"}
          icon={"pi pi-save"}
          className="p-button-outlined"
          onClick={save}
        />
        <Button
          label={"Discard Changed"}
          icon={"pi pi-undo"}
          className="p-button-outlined p-button-danger"
          onClick={() => navigate("..")}
        />
      </ControlWrapper>
    </div>
  );
};

export default PostEditor;
