import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import App from "./App"

import Layout from "../components/layout-main";
const AdminIndex = ({ data, location }) => {
  console.log('admin index');
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Layout location={location} title={siteTitle}>
       <App location={location}/>
    </Layout>
  );
};

export default AdminIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
