import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "primereact/button";
import { uploadImage, UnauthroizedError } from "../../adminApi";
import { Link } from "@reach/router";

const HiddenFileInput = styled.input.attrs({ type: "file" })`
  display: none;
`;

const SpacingWrapper = styled.div`
  margin: 5px 0px;
`;

const FileSelect = ({ setSelectedFiles }) => {
  const fileInput = useRef(null);
  return (
    <>
      <Button
        onClick={() => fileInput.current.click()}
        icon={"pi pi-images"}
        label={"Select Files"}
      />
      <HiddenFileInput
        ref={fileInput}
        multiple
        onChange={(e) => setSelectedFiles(Array.from(e.target.files))}
      />
    </>
  );
};

const Index = () => {
  const [uploadFiles, setUploadFiles] = useState(null);
  const [uploading, setUploading] = useState(false);

  const filesToUpload = (fileList) => {
    setUploadFiles(
      fileList.map((item) => ({ file: item, uploaded: false, result: null }))
    );
  };

  const doUploadLoad = async () => {
    setUploading(true);
    //this is needed because setUploadFiles does not run in sync, so we need to keep a synced list with the updates
    let newList = [...uploadFiles];

    // this uploads in parallel, maybe too much load on server.
    /*
    uploadFiles.map((item) =>
      uploadImage(item.file).then((result) => {
        const tempList = [];
        for (let fileItem of newList) {
          if (fileItem.file.name === item.file.name) {
            tempList.push({ ...fileItem, result, uploaded: true });
          } else {
            tempList.push(fileItem);
          }
        }
        newList = tempList;
        setUploadFiles(newList);
      })
    );*/

    //upload in series
    for (let item of uploadFiles) {
      await uploadImage(item.file).then((result) => {
        const tempList = [];
        for (let fileItem of newList) {
          if (fileItem.file.name === item.file.name) {
            tempList.push({ ...fileItem, result, uploaded: true });
          } else {
            tempList.push(fileItem);
          }
        }
        newList = tempList;
        setUploadFiles(newList);
      });
    }
  };

  return (
    <>
      {!uploading && (
        <SpacingWrapper>
          <FileSelect setSelectedFiles={filesToUpload} />
        </SpacingWrapper>
      )}
      <div>
        {uploadFiles &&
          uploadFiles.map((fileItem, index) => (
            <div key={index}>
              {fileItem.file.name}{" "}
              {uploading && !fileItem.uploaded && (
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "0.8em" }}
                ></i>
              )}
              {uploading && fileItem.uploaded && (
                <i className="pi pi-check" style={{ fontSize: "0.8em" }}></i>
              )}
              {/*fileItem.result && (
                <img
                  src={
                    fileItem.result.filter((item) => item.type === "200")[0].url
                  }
                />
                )*/}
            </div>
          ))}
      </div>
      {!uploading && uploadFiles && <Button onClick={doUploadLoad}>Upload</Button>}
    </>
  );
};

export default Index;
