const API_HOST = process.env.GATSBY_API_HOST;

export class UnauthroizedError extends Error {
  constructor() {
    super("You are not authorized to access this resource");
    this.name = "UnauthorizedError";
  }
}

export const responseCodeFilter = (res) => {
  if (res.status < 200 || res.status >= 400) {
    switch (res.status) {
      case 401:
        throw new UnauthroizedError();
      default:
        throw new Error(`There was an error fetching... code [${res.status}]`);
    }
  }
  return res;
};

export const filteredFetch = (url, object) => {
  return fetch(`${API_HOST}${url}`, object).then(responseCodeFilter);
};

export const login = (user, pass) => {
  return filteredFetch(`/api/admin/users/login`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    body: JSON.stringify({ username: user, password: pass }),
  })
    .then((res) => res.json())
    .then((json) => json.success);
};

export const checkLogin = () => {
  return filteredFetch(`/api/admin/users/me`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
};

export const uploadImage = (file) => {
  const formData = new FormData();
  console.log(file);
  formData.append("file", file);
  console.log(formData)
  return filteredFetch(`/api/1/images/upload`, {
    method: "POST",
    credentials: "include",
    /*headers: {
      "Content-Type": "multipart/form-data",
    },*/
    body: formData
  }).then((res) => res.json());
};

export const updatePassword = (data) => {
  /*const a = new Promise((resolve, reject) => {
    const newdata = { ...data, inviteCode: "NEWCODE" };
    guestsMock.push(newdata);
    resolve(newdata);
  });
  return a;*/
  return filteredFetch(`/api/admin/users/me/changePassword`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const publishSite = () => {
  return filteredFetch(`/api/admin/publish`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
};
