import React, { useEffect, useState } from "react";
import { getAdminArticles, deleteArticle } from "../../articleResource";
import { Link } from "@reach/router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import styled from "styled-components";
import { navigate } from "gatsby";

import {LoadingWrapper} from "../loading"

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

const ListRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 5px 0px 0px 0px;
  padding: 0px 0px 5px 0px;

  border-bottom: 1px solid #ededed;
`;

const ArticleNameSection = styled.div`
  flex: 1;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  //& > button {
  //  margin: 0 5px;
  //}
`;

const ButtonStyled = styled(Button)`
  margin: 0 5px;
`;

const PostList = () => {
  //console.log("admin post list");
  const [posts, setPosts] = useState([]);
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  //console.log(posts);

  const LoadArticles = async () => {
    try {
      const result = await getAdminArticles();
      setPosts(result);
      return true;
    } catch {
      return false;
    }
  };

  /*useEffect(() => {
    getAdminArticles().then((result) => setPosts(result));
  }, []);*/

  const del = (id) => {
    deleteArticle(id).then(() =>
      getAdminArticles().then((result) => setPosts(result)).then(() => setDeleteConfirm(null))
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => setDeleteConfirm(null)}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => del(deleteConfirm)}
        />
      </div>
    );
  };

  return (
    <LoadingWrapper doLoad={LoadArticles}>
      <ListWrapper>
        {posts.map((post) => (
          <ListRow key={post.id}>
            <ArticleNameSection>
              <Link to={`/admin/draft/${post.slug}`}>{post.title}</Link>
            </ArticleNameSection>
            <ButtonSection>
              <ButtonStyled
                className="p-button-rounded p-button-outlined"
                icon={faEdit}
                onClick={() => navigate(`edit/${post.id}`)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </ButtonStyled>

              <ButtonStyled
                className="p-button-danger p-button-rounded p-button-outlined"
                icon={faTrashAlt}
                onClick={() => setDeleteConfirm(post.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </ButtonStyled>
            </ButtonSection>
          </ListRow>
        ))}
      </ListWrapper>
      <Dialog
        header="Are you sure?"
        visible={deleteConfirm !== null}
        style={{ width: "50vw" }}
        footer={renderFooter()}
        onHide={() => setDeleteConfirm(null)}
      >
        <p>Are you sure you want to delete this article?</p>
      </Dialog>
      {/*<ui>
      {posts.map((post) => (
        <li key={post.id}>
          <Link to={`edit/${post.id}`}>{post.title}</Link>
          <button onClick={() => del(post.id)}>Delete</button>
          <Link to={`draft/${post.slug}`}>View</Link>
        </li>
      ))}
      </ui> */}
    </LoadingWrapper>
  );
};

export default PostList;
