import React, { useEffect, useState } from "react";
import { Link, Router } from "@reach/router";
import Upload from "./upload";
import { PropertyEditor } from "../../components/gallery";
import { LoadingWrapper } from "../loading";
import { getAdminImages } from "../../imageResource";

import { publishSite } from "../../adminApi";
import styled from "styled-components";
import { Button } from "primereact/button";

const ControlSection = styled.div`
  display: flex;
  margin: 10px 0px;

  & > button {
    margin: 0px 0px;
  }
`
const Admin = () => {
  //const [isLoaded, setLoaded] = useState(false);

  //useEffect(() => {}, []);

  const [imageList, setImageList] = useState([]);

  const doLoad = async () => {
    setImageList(await getAdminImages());
    return true;
  };

  const updateImage = (image) => {
    const newList = [];
    for (let item of imageList) {
      if (item.id === image.id) {
        newList.push(image);
      } else {
        newList.push(item);
      }
    }
    setImageList(newList);
  };

  return (
    <>
      <ControlSection>
        <Button icon={"pi pi-cloud-upload"} label={"Publish"} onClick={async () => await publishSite()}/>
      </ControlSection>
      <LoadingWrapper doLoad={doLoad}>
        <PropertyEditor images={imageList} updated={updateImage} />
      </LoadingWrapper>
    </>
  );
};

const Index = () => (
  <>
    <Router>
      <Admin path="/" />
      <Upload path="/upload" />
    </Router>
  </>
);

export default Index;
