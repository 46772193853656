import React from "react";
import { Link, Router } from "@reach/router";
import ArticleDisplay from "../components/ArticleDisplay";
import { useEffect, useState } from "react";
import { getArticleBySlug } from "../articleResource";
import LoginScreen from "./Login";
import { checkLogin, UnauthroizedError, login, publishSite } from "../adminApi";
import { navigate } from "gatsby";

import { TabMenu } from "primereact/tabmenu";

import BlogAdmin from "./blog";
import ImageAdmin from "./images";

const DisplayDraft = ({ slug }) => {
  const [article, setArticle] = useState();
  useEffect(() => {
    (async () => {
      setArticle(await getArticleBySlug(slug));
    })();
  }, [slug]);
  return (
    <>
      <ArticleDisplay article={article} />
      <Link to="/admin/">Back</Link>
    </>
  );
};

const AdminPortal = ({location, loginState}) => {
  const menuItems = [
    {
      label: "Blog",
      icon: "pi pi-fw pi-pencil",
      command: () => navigate("/admin/blog"),
      matchUrl: "/admin/blog",
    },
    {
      label: "Images",
      icon: "pi pi-fw pi-camera",
      command: () => navigate("/admin/images"),
      matchUrl: "/admin/images",
    },
    {
      label: "Upload",
      icon: "pi pi-fw pi-cloud-upload",
      command: () => navigate("/admin/images/upload"),
      matchUrl: "/admin/images/upload",
    },
    /*{ label: "Settings", icon: "pi pi-fw pi-cog" },*/
  ];

  const matches = menuItems.filter(
    (item) => (location.pathname || window.location.pathname).startsWith(item.matchUrl)
  );

  let match = null;
  if (matches && matches.length > 0) {
    match = matches[0];
    for(let possibleMatch of matches){
      if(match.matchUrl.split('/').length < possibleMatch.matchUrl.split('/').length){
        match = possibleMatch;
      }
      else if(match.matchUrl.split('/').length == possibleMatch.matchUrl.split('/').length && match.matchUrl.length <= possibleMatch.matchUrl.length)
      {
        match = possibleMatch;
      }
    }
  } else {
    if(typeof window !== 'undefined' && window)
      navigate(menuItems[0].matchUrl);
  }

  return (
    <>
      <h2>Administration</h2>
      <TabMenu
        model={menuItems}
        activeItem={match}
        onTabChange={(e) => console.log(e.value)}
      />
      <Router>
        <BlogAdmin path="blog/*" loginState={loginState}></BlogAdmin>
        <ImageAdmin path="images/*"></ImageAdmin>
      </Router>
    </>
  );
};

function App({ location }) {
  const [loginState, setLoginState] = useState(false);

  useEffect(() => {
    authCheck();
  }, []);

  const authCheck = async () => {
    try {
      const user = await checkLogin();
      setLoginState(user);
      if (window.location.pathname === "/admin/login") navigate("/admin");
    } catch (err) {
      if (err instanceof UnauthroizedError) {
        if (window.location.pathname !== "/admin/login")
          navigate("/admin/login");
      }
    }
  };

  const submitLogin = async ({ user, pass }) => {
    if (await login(user, pass)) {
      await authCheck();
    }
  };

  return (
    <>
      <Router>
        <LoginScreen path="/admin/login" submit={submitLogin} />
        <DisplayDraft path="/admin/draft/:slug" loginState={loginState} />
        <AdminPortal
          path="/admin/*"
          loginState={loginState}
          location={location}
        />
      </Router>
    </>
  );
}

export default App;
