import React, { useState } from "react";
import {
  TextField,
  ButtonComponent,
  PasswordField,
  CheckboxComponent,
} from "../components/PrimeReact";

const LoginScreen = (props) => {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");

  const submit = () => {
    props.submit({ user, pass });
  };

  return (
    <>
      <TextField
        id={"user"}
        label={"User Name"}
        value={user}
        onChange={(e) => setUser(e.target.value)}
      />
      <PasswordField
        id={"pass"}
        label={"Password"}
        value={pass}
        onChange={(e) => setPass(e.target.value)}
        onKeyDown={(e) => {
          console.log('keydown')
          if (e.key === "Enter") {
            submit();
          }
        }}
      />
      <ButtonComponent label="Login" onClick={submit} />
    </>
  );
};

export default LoginScreen;
