import React, { useState, useEffect } from "react";

import styled from "styled-components";

const Loading = () => <>Loading...</>;

export const LoadingWrapper = ({ children, doLoad }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (doLoad) {
      (async () => {
        const result = await doLoad();
        if (result === true) {
          setIsLoaded(true);
        }
      })();
    }
  }, []);
  if (isLoaded) {
    return children;
  } else {
    return <Loading />;
  }
};

export default Loading;
