import React from "react";
import PostList from "./PostListing";
import { Link, Router } from "@reach/router";
import PostEditor from "./PostEditor";
import { publishSite } from "../../adminApi";
import { Button } from "primereact/button";

import styled from "styled-components";
import { navigate } from "gatsby";

const ControlSection = styled.div`
  display: flex;
  margin: 5px;

  & > button {
    margin: 0 5px;
  }
`;

const ArticleSelection = () => {
  return (
    <>
      <ControlSection>
        <Button
          icon={"pi pi-plus"}
          label={"New"}
          onClick={async () => navigate("new")}
        />
        <Button
          icon={"pi pi-cloud-upload"}
          label={"Publish"}
          onClick={async () => await publishSite()}
        />
      </ControlSection>

      <PostList />
    </>
  );
};

const Index = ({ loginState }) => (
  <>
    <Router>
      <ArticleSelection path="/" default />
      <PostEditor path="new" loginState={loginState} />
      <PostEditor path="edit/:id" loginState={loginState} />
    </Router>
  </>
);

export default Index;
